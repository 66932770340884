<template>
  <WeContainer v-if="ready" header="true" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data.sync="brandType.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="true"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:order-changed="onOrderChange"
      v-on:FilterLanguage="FilterLanguage"
      v-bind:stateName="'brand-types'"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          text: "Yeni Marka Tipi",
          path: "brand-types/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "brand-types/trash",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "name", th: "Tip Adı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
        { name: "locales", th: "Dil", type: "language" },
      ],
    };
  },
  methods: {
    ...mapActions("brandType", ["getList", "delete", "isActive"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("brand-types/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.name} adlı markayı silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Marka Tipi Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Marka Tipi Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success("Durum Güncellendi");
            } else {
              this.$toast.error("Durum Güncellenemedi");
            }
          },
          onError: (error) => {
            this.$toast.error("Durum Güncellenemedi");
          },
        });
      }
    },
    onOrderChange(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.orderUpdate({
          form: data,
          onSuccess: (result) => {
            this.showAlert(result.data.status, "Marka Sırası Güncellendi");
          },
          onError: () => {
            this.showAlert("error", "Marka Sırası Güncellenemedi");
          },
        });
      }
    },
    showAlert(type, message) {
      if (type) {
        switch (type) {
          case "success":
            this.$toast.success(message);
            break;

          default:
            this.$toast.error(message);
            break;
        }
      } else {
        this.showAlert("error", message);
      }
    },
    checkPermission(code) {
      return permission.check("brand", code);
    },
    FilterLanguage(lang) {
      this.getList({
        lang: lang,
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["shared", "brandType"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
  },
};
</script>
